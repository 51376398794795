import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { Location } from '@reach/router';
import { HamburgerSpin } from 'react-animated-burgers';
import Logo from '../images/klaser_logo.svg';

class Header extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired
  }
  constructor(props) {
    super(props);
    this.state ={
      isActive: false
    }
  }
  componentDidMount() {
    window.addEventListener("scroll", this.resizeHeaderOnScroll);
  }
  resizeHeaderOnScroll() {
    const distanceY = window.pageYOffset || document.documentElement.scrollTop,
      shrinkOn = 100,
      headerEl = document.getElementById("header"),
      logoEl = document.getElementById("logo")

    if (distanceY > shrinkOn) {
      headerEl.classList.add("smaller");
      logoEl.classList.add("smaller")
    } else {
      headerEl.classList.remove("smaller");
      logoEl.classList.remove("smaller");
    }
  }
  toggleButton = () => {
    this.setState({
      isActive: !this.state.isActive
    })
  }

  render() {
    const { location } = this.props;
    return (
      <header>
        <div className="navigation-wrapper">
          <div className="navigation" id="header">
            <div className="container in-navigation w-container">
              <div className="navigation-left">
                <Link to="/" id="logo" className="logo-block w-inline-block w--current">
                  <div className="logo-text w-hidden-tiny"><img src={Logo} alt="" style={{ width:95 }} /></div>
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/about" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  關於光群
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/truseamless" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  無版縫技術
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/products" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  產品應用
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/services" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  服務項目
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/events" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  最新動態
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/investor" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  投資人關係
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to="/inventory" activeStyle={{ color: "white", backgroundColor:"#3499ff" }} className="nav-item w-hidden-medium w-hidden-small w-hidden-tiny w-inline-block ">
                  登入
                </Link>
              </div>
              <div className="navigation-left site-header">
                <Link to={`/en${location.pathname}`} className="logo-block w-inline-block w--current">
                  <div className="nav-item">English</div>
                </Link>
              </div>
              <div className="mobile-only">
                <img src={Logo} alt=""  style={{ width:65, margin:"75%" }} />
              </div>
              <div className="navigation-right mobile-only">
                <HamburgerSpin style={{ outline:"none" }} isActive={this.state.isActive} toggleButton={this.toggleButton} barColor="black" />
              </div>
            </div>
          </div>
          <div className={ this.state.isActive ? "navigation-content-active" : "navigation-content"}>
            <div className="container center w-container">
              <div className="navigation-block _1">
                <Link to="/" className="navigation-link w-inline-block">
                  <h1 className="nav-text">首頁</h1>
                </Link>
              </div>
              <div className="navigation-block _2">
                <Link to="/about" className="navigation-link w-inline-block">
                  <h1 className="nav-text">關於光群</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _3">
                <Link to="/truseamless" className="navigation-link w-inline-block">
                  <h1 className="nav-text">無版縫技術</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _4">
                <Link to="/products" className="navigation-link w-inline-block">
                  <h1 className="nav-text">產品應用</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _5">
                <Link to="/services" className="navigation-link w-inline-block">
                  <h1 className="nav-text">服務項目</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _6">
                <Link to="/events" className="navigation-link w-inline-block">
                  <h1 className="nav-text">最新動態</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _6">
                <Link to="/investor" className="navigation-link w-inline-block">
                  <h1 className="nav-text">投資人關係</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>
              <div className="navigation-block _6">
                <Link to="/inventory" className="navigation-link w-inline-block">
                  <h1 className="nav-text">登入</h1>
                  <div className="navigation-line"></div>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </header>
    )
  }
}

export default props => (
  <Location>
    {locationProps => <Header {...locationProps} {...props} />}
  </Location>
);
