import React, { Component } from 'react';

class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "none" };
  }

  inactiveMessage = (event) => {
    event.preventDefault();
    this.setState(
      () => ({
        message: "none",
      })
    )
  }

  activeMessage = (event) => {
    event.preventDefault();
    this.setState(
      () => ({
        message: "",
      })
    )
  }

  render() {
    return (
      <div>
        <div className="is-floating-form" style={{ display: this.state.message }}>
          <div className="is-floating-form-wrapper">
            <div className="contact-us-form container w-container">
              <form action="https://formspree.io/xqkzwzgb" method="POST">
                <div className="contact-input-row">
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='姓' type='text' name='last' />
                  </div>
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='名' type='text' name='first' />
                  </div>
                </div>
                <div className="contact-input-row">
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='電子郵件' type='email' name='email' />
                  </div>
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='電話號碼' type='text' name='phone' />
                  </div>
                </div>
                <div className="contact-input-row">
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='公司' type='text' name='company' />
                  </div>
                  <div className="w-col w-col-6">
                    <input className="text-field w-input" placeholder='職稱' type='text' name='position' />
                  </div>
                </div>
                <input className="text-field w-input" placeholder='國家' type='text' name='country' />
                <textarea className="text-field area w-input" label='訊息' name="message" placeholder='歡迎提出任何疑問...' />
                <button className="button" type="submit">送出</button>
              </form>
            </div>
          </div>
        </div>
        <button className="contact-button" onClick={this.state.message === "" ? this.inactiveMessage : this.activeMessage}>
          <div className="contact-link-text">聯絡我們</div>
        </button>
      </div>
    )
  }
}

export default Contact;
