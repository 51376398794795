import { Link } from "gatsby";
import React from "react";
import Logo from '../images/klaser_logo.svg';

const Footer = () => (

  <div className="footer">
    <div className="container w-container">
      <div className="w-row">
        <div className="w-col w-col-5 w-col-stack">
          <div className="footer-wrapper">
            <a href="#" className="line-wrapper-second in-top w-inline-block">
              <div className="link-text-second">最上頁</div>
              <div className="link-line-second"></div>
            </a>
            <div className="footer-title">
              <h4 className="footer-sub-title">社群媒體</h4>
            </div>
            <div className="top-margin medium">
              <div className="footer-social">
                <a href="https://www.instagram.com/klasergroup/" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-instagram" style={{ color: "#232323", fontSize:30 }} />
                </a>
                <a href="https://www.facebook.com/KLASERTAIWAN" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-facebook" style={{ color: "#232323", fontSize:30 }} />
                </a>
                <a href="https://twitter.com/KLaserGroup" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-twitter" style={{ color: "#232323", fontSize:30 }} />
                </a>
                <a href="https://www.behance.net/KLaserGroup" target="_blank" rel="noopener noreferrer" className="social-icon w-inline-block">
                  <i className="fab fa-behance" style={{ color: "#232323", fontSize:30 }} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="w-col w-col-5 w-col-stack">
          <div className="footer-wrapper">
            <div className="footer-title">
              <h4 className="footer-sub-title">網站導引</h4>
            </div>
            <div className="top-margin medium">
              <div>
                <Link to="/about/" className="line-wrapper-second second in-footer-nav w-inline-block">
                  <div className="link-text-second">關於光群</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
              <div>
                <Link to="/truseamless/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">無版縫技術</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
                <Link to="/products/" className="line-wrapper-second second in-footer-nav w-inline-block">
                  <div className="link-text-second">產品應用</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/services/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">服務項目</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/events/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">最新動態</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/investor/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">投資人關係</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>
              <div>
                <Link to="/inventory/" className="line-wrapper-second second in-footer-nav w-inline-block w--current">
                  <div className="link-text-second">登入</div>
                  <div className="link-line-second"></div>
                </Link>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="line-divider"></div>
      <div>
        <div className="flex-row make-normal w-row">
          <div className="w-col w-col-6 w-col-stack">
            <div>
              <p className="copyright">© {new Date().getFullYear()} K Laser Technology Inc.</p>
              <p style={{ fontSize:12, marginBottom: 0 }}>Klasergroup.com已不再支援 Internet Explorer。</p>
              <p style={{ fontSize:12 }}>請改用 Chrome 瀏覽器, 以確保您享有最佳網頁體驗。</p>
            </div>
          </div>
          <div className="w-col w-col-6 w-col-stack">
            <div className="align-left make-with-margin">
              <Link to="/" className="footer-title w-inline-block"><img src={Logo} width="100" alt="logo" style={{ padding:"12px" }}/></Link>
              <div className="footer-title w-inline-block"><img src="https://res.cloudinary.com/cortlandt-labs/image/upload/c_scale,h_100,q_auto:best/v1580767641/IHMA_logo_i1kdlt.png" width="100" alt="logo" style={{ padding:"12px" }}/></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Footer;
